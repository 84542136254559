import React, {useEffect} from 'react';
import useWindowDimensions from "../utils/useWindowDimensions";
import {Trans, useTranslation} from "react-i18next";
import {Button, Chip} from '@progress/kendo-react-buttons';
import {Checkbox, TextBox, TextBoxChangeEvent, TextBoxProps} from "@progress/kendo-react-inputs";
import Turnstile from "react-turnstile";
import {useNotifiableUserSelector, useNotifiableUserServiceDispatch} from "../redux/store";
import {postAddUserEmailThunk} from "../redux/notifiableUserSlice";
import {NotifiableUserModel} from "../model/NotifiableUserModel";
import {useNavigate} from "react-router";
import alertLogo from '../asset/resource/glyphicons-basic-638-triangle-alert.svg'
import CookieConsent from "react-cookie-consent";
import {Config} from "../Config";
import Spinner from '../ui/spinner/Spinner';

const EMPTY_VALUE: string = "";
const matchEmail = (value: TextBoxProps["value"]) =>
    new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g).test(String(value));

export function LandingPage() {

    const {isResponsive} = useWindowDimensions();
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();

    // const [value, setValue] = React.useState<TextBoxProps["value"]>(EMPTY_VALUE);
    const [value, setValue] = React.useState<string>(EMPTY_VALUE);
    const [token, setToken] = React.useState<string>();
    const [privacyAgreement, setPrivacyAgreement] = React.useState<Boolean>();

    const valid = matchEmail(value);

    const notifiableUserDispatch = useNotifiableUserServiceDispatch();
    const successfulPostEmailAddress = useNotifiableUserSelector((state) => state.notifiableUser.successfulPostEmailAddress) as boolean;
    const errorPostEmailAddress = useNotifiableUserSelector((state) => state.notifiableUser.errorPostEmailAddress) as boolean;
    const loading = useNotifiableUserSelector((state) => state.notifiableUser.loading) as boolean;

    const bannerImage = "https://res.cloudinary.com/arrowhacks/image/upload/c_fill,g_auto,h_250,w_970/b_rgb:000000,e_gradient_fade,y_-0.50/c_scale,co_rgb:ffffff,fl_relative,w_0.5,y_0.18/v1702304584/arrowfight/preannouncement/prenauncement_1024.png";

    useEffect(() => {
        window.scrollTo({top: 0});
    }, []);

    useEffect(() => {
        if (successfulPostEmailAddress) {
            navigate("/thankyou")
        }
    }, [successfulPostEmailAddress]);

    const handleChange = React.useCallback((event: TextBoxChangeEvent) => {
        setValue(event.target.value as string);
    }, []);

    function enableSubmitButton() {
        if (valid && token && privacyAgreement) {
            return true
        }
        return false;
    }


    function submitEmail() {
        if (value && token) {
            const notifiableUser: NotifiableUserModel = {
                email: value,
                token: token
            };
            notifiableUserDispatch(postAddUserEmailThunk({notifiableUserRequest: notifiableUser}));
            // setValue("");
        }
    }

    // useEffect(() => {
    //     if (token) {
    //         console.log("### token -> ", token);
    //     }
    // }, [token]);

    function handleCheckboxChange() {
        setPrivacyAgreement(!privacyAgreement);
    }

    return (

        <div style={isResponsive ? {paddingTop: "0px"} : {paddingTop: "30px"}}>
            <div>
                <div className="fullCenter">
                    <img src={bannerImage} alt='arrowfight banner' className="prenouncementImage"/>
                </div>
                <div className="fullCenter full-width">
                    {isResponsive
                        ? <h1><>
                            <div className="justify-center">
                                {t("landingPage.section_1.content_1a")}
                            </div>
                            <div className="justify-center padding-top-l">
                                {t("landingPage.section_1.content_1b")}
                            </div>
                        </>
                        </h1>
                        : <h1>{t("landingPage.section_1.content_1")}</h1>}
                </div>
                {loading && <Spinner/>}
                <div className="fullCenter justify-center text-size-xl">
                    <div>
                        <Trans i18nKey="landingPage.section_1.content_2a">
                            <span style={{color: "#E30C17"}}/>
                        </Trans>
                        <div className="row-direction">
                            <div className="padding-top-xl">
                                {t("landingPage.section_1.content_2b")}
                            </div>
                            <div className="padding-left-l">
                                <Chip text={t("launchText")} className="beta-chip-position beta-chip-style"
                                      fillMode="outline"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="fullCenter margin-top-xxl">
                    <div className="justify-center text-size-m padding-bottom-l">
                        {t("landingPage.section_1.content_3")}
                    </div>
                </div>
            </div>
            <div>
                <div className="fullCenter">
                    <div className="row-direction">
                        <div className={isResponsive ? "" : "max-width-600"}>
                            <TextBox
                                size="medium"
                                fillMode="outline"
                                rounded="large"
                                placeholder={t("landingPage.emailTextTemplate")}
                                onChange={handleChange}
                                required={true}
                                valid={valid}
                                className="text-box text-size-m"
                                value={value}
                            />
                            <div className="padding-top-s">
                                <Checkbox className="checkbox" defaultChecked={false} label={t("checkboxPrivacy")}
                                          onClick={handleCheckboxChange}/>
                            </div>
                            <div className="padding-top-s">
                                <div className="font-light text-size-s">{t("acceptNotification")}</div>
                            </div>
                        </div>
                        <div className="align-self-baseline padding-left-s">
                            {!isResponsive && <Button disabled={!enableSubmitButton() || loading}
                                                      className="text-box-button k-button k-button-solid-primary full-height"
                                                      onClick={submitEmail}>{t("button.submit")}</Button>}
                        </div>
                    </div>
                </div>

                <div className="align-self-baseline padding-top-l">
                    {isResponsive &&
                        <Button disabled={!enableSubmitButton() || loading}
                                className="text-box-button k-button k-button-solid-primary full-height full-width"
                                onClick={submitEmail}>{t("button.submit")}</Button>
                    }
                    <div className="fullCenter padding-top-l">
                        <div className="column-direction">
                            {errorPostEmailAddress && <div className="text-size-m row-direction">
                                <div>{t("errorPostMessage")}</div>
                                <div className="padding-left-s">
                                    <img src={alertLogo} alt="alert logo" className="alert-icon"/>
                                </div>
                            </div>}
                            <div className={isResponsive ? "fullCenter padding-top-m" : "fullCenter padding-top-l"}>
                                <Turnstile
                                    sitekey={Config.site_key}
                                    onVerify={(token) => setToken(token)}
                                />
                            </div>
                        </div>

                    </div>
                </div>
                <div>
                    <div className="normal-font">
                        <CookieConsent buttonText={t("cookieButtonText")}>{t("cookieText")}</CookieConsent>
                    </div>
                </div>
            </div>
        </div>
    );
}
