import * as React from 'react';
import {useState} from 'react';

import {AppBar, AppBarSection, AppBarSpacer} from '@progress/kendo-react-layout';
import {useNavigate} from "react-router";
// @ts-ignore
import Cookies from "js-cookie";
import {useTranslation} from "react-i18next";
import arrowfightLogo from '../asset/resource/ARROWFIGHT logo Delivery-02.svg'
import useWindowDimensions from "../utils/useWindowDimensions";
import {useDispatch} from "react-redux";
import {setLanguage} from "../redux/settingSlice";
import {Link} from "react-router-dom";

//https://dev.to/receter/so-easy-svg-with-currentcolor-in-create-react-app-1aid
//https://www.copycat.dev/blog/react-svg/

export default function TopAppBar() {

    const {isResponsive} = useWindowDimensions();
    const navigate = useNavigate();

    const dispatch = useDispatch();

    const languages = [
        {name: "EN", code: "en"},
        {name: "DE", code: "de"}
    ];

    const currentLocale = Cookies.get("i18next") || "en";
    const [lang, setLang] = useState(currentLocale);
    const {t, i18n} = useTranslation();

    const handleChangeLocale = (e: any) => {
        const lang = e.target.value;
        setLang(lang);
        i18n.changeLanguage(lang);
        dispatch(setLanguage(lang));
    };

    function handleLogoClicked() {
        navigate('/');
    }

    function getPrivacyLinkTo() {
        if (!currentLocale) {
            return '/privacy';
        }
        if (currentLocale === "de") {
            return '/privacy';
        } else {
            return '/privacy-en';
        }
    }

    return (
        <React.Fragment>
            <AppBar className="app-bar-top">
                <AppBarSection>
                    <img src={arrowfightLogo} alt="arrowhacks logo" className="app-logo" onClick={handleLogoClicked}/>
                </AppBarSection>
                {!isResponsive && <AppBarSpacer/>}
                {/*<AppBarSpacer/>*/}
                {/*<AppBarSpacer/>*/}
                {/*<AppBarSection className="text-size-m">*/}
                {/*    <Link to='/legalnotice'*/}
                {/*          className="text-color-white">{t("landingPage.footer.impressum_link")}</Link>*/}
                {/*</AppBarSection>*/}
                {/*<AppBarSection className="text-size-m">*/}
                {/*    <Link to={getPrivacyLinkTo()}*/}
                {/*          className="text-color-white">{t("landingPage.footer.privacy_link")}</Link>*/}
                {/*</AppBarSection>*/}

                <AppBarSpacer/>
                <AppBarSection className="actions">
                    <div className="switcher">
                        <select onChange={handleChangeLocale} value={lang} className="switcher">
                            {languages.map(({name, code}) => (
                                <option key={code} value={code}>
                                    {name}
                                </option>
                            ))}
                        </select>
                    </div>
                </AppBarSection>
            </AppBar>

        </React.Fragment>
    );
};